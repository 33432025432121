<template>
  <div
    v-if="user"
    class="flex flex-col gap-4 items-center w-full"
  >
    <ProfilePictureEdit v-if="edit" />
    <ProfilePicture
      v-else
      :class="css ? css : 'w-36 h-36'"
    />
    <div class="flex flex-col items-center">
      <h1>
        {{ user.firstName }} {{ user.lastName }}
      </h1>
      <span class="text-sm text-neutral-500">
        Joined in {{ getYear(new Date(user.createdAt)) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getYear } from 'date-fns'

interface Props {
  edit?: boolean
  css?: string
}
defineProps<Props>()
const auth = useAuthStore()
const user = computed(() => auth.shopify.customer)
</script>
